import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '@/assets/images/right-arrow-black.svg'


const _hoisted_1 = { class: "body_wrap about_page" }
const _hoisted_2 = { class: "question_game" }
const _hoisted_3 = { class: "container pb-5" }
const _hoisted_4 = { class: "filter_outer cmn_cardInner mb-5" }
const _hoisted_5 = { class: "category_list" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "filter_img" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "filter_description mt-3" }
const _hoisted_10 = { class: "text-center" }
const _hoisted_11 = { class: "text-center mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_section = _resolveComponent("header-section")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_header_section),
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[2] || (_cache[2] = _createElementVNode("h2", { class: "text-center mb-5" }, "Select the Category of Your Interest", -1)),
          _createElementVNode("ul", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pickerItems, (pickerItem, index) => {
              return (_openBlock(), _createElementBlock("li", {
                class: _normalizeClass({
                active: _ctx.currentItem.type === pickerItem.type,
              }),
                key: index
              }, [
                _createElementVNode("button", {
                  onClick: ($event: any) => (_ctx.onClickPickerItem(index))
                }, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("img", {
                      src: pickerItem.image,
                      alt: ""
                    }, null, 8, _hoisted_8)
                  ]),
                  _createElementVNode("p", null, _toDisplayString(pickerItem.title), 1)
                ], 8, _hoisted_6)
              ], 2))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.currentPickerDescription), 1)
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("button", {
              class: "cm_bg cm-btn text-white",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToPicker && _ctx.goToPicker(...args)))
            }, _cache[1] || (_cache[1] = [
              _createTextVNode(" Pick a Question "),
              _createElementVNode("img", {
                src: _imports_0,
                class: "ml-1",
                alt: ""
              }, null, -1)
            ]))
          ])
        ])
      ])
    ])
  ]))
}