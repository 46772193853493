
import { defineComponent } from "vue";
import { Question } from "@/interfaces/topics";
import HeaderSection from "../HeaderSection.vue";
import AnswerSection from "@/components/question/answer/AnswerSection.vue";
import ResourceSection from "@/components/question/resource/ResourceSection.vue";
import { getQuestionPicker } from "@toppick/common/build/api/question";
import AuthModal from "@/components/auth/AuthModal.vue";
import Loader from "@/components/ui/Loader.vue";
import { QuestionType } from "@toppick/common/build/interfaces";
import { TopicPageTab } from "@/interfaces/ui";
import { pickerItems } from "../start/Index.vue";
import "../../styles.css";
import { getAuthToken } from "@/utils/auth";

const PRELOADED_ANSWER_COUNT = 3;
const PRELOADED_RESOURCE_COUNT = 3;

export default defineComponent({
  emits: ["onDelete", "onShowAuthModal"],
  components: {
    HeaderSection,
    AnswerSection,
    ResourceSection,
    AuthModal,
    Loader,
  },
  data() {
    return {
      question: null as Question | null,
      isLoading: false,
      isPageLoading: true,
      isShowAuthModal: false,
      TopicPageTab,
    };
  },
  methods: {
    async loadData() {
      this.question = await getQuestionPicker(await getAuthToken(), {
        answer_count: PRELOADED_ANSWER_COUNT,
        resource_count: PRELOADED_RESOURCE_COUNT,
        include_answers: true,
        include_resources: true,
        type: this.currentType,
      });
    },
    async onClickNext() {
      this.isLoading = true;
      try {
        await this.loadData();
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },

    onClose() {
      this.$router.push({
        name: "QuestionPickerStart",
      });
    },

    toggleAuthModal() {
      this.isShowAuthModal = !this.isShowAuthModal;
    },
  },

  computed: {
    currentType(): QuestionType {
      let type: QuestionType = QuestionType.Default;
      const item = pickerItems.find(
        (item) => item.query === this.$route.query.type
      );
      if (item) {
        type = item.type;
      }
      return type;
    },
    currentPickerTitle(): string {
      return pickerItems.find((item) => item.type === this.currentType)!.title;
    },
  },

  async mounted() {
    this.$watch(
      () => ({
        type: this.currentType,
      }),
      async () => {
        try {
          this.isPageLoading = true;
          await this.loadData();
          this.isPageLoading = false;
        } catch (error) {
          console.log(error);
        }
      },
      { immediate: true }
    );
  },
});
