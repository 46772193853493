import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/images/fa-solid_angle-right.png'
import _imports_1 from '@/assets/images/Vector.png'


const _hoisted_1 = { class: "body_wrap about_page" }
const _hoisted_2 = { class: "question_game" }
const _hoisted_3 = { class: "container pb-5" }
const _hoisted_4 = {
  key: 0,
  class: "filter_outer mb-5"
}
const _hoisted_5 = {
  key: 0,
  class: "linear_progress"
}
const _hoisted_6 = {
  key: 1,
  class: "loader_overlay"
}
const _hoisted_7 = { class: "filter_header" }
const _hoisted_8 = { class: "filter_content" }
const _hoisted_9 = { class: "mt-3 title" }
const _hoisted_10 = {
  class: "card-body",
  key: "{{question.id}}"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_section = _resolveComponent("header-section")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_answer_section = _resolveComponent("answer-section")!
  const _component_resource_section = _resolveComponent("resource-section")!
  const _component_auth_modal = _resolveComponent("auth-modal")!
  const _component_loader = _resolveComponent("loader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_header_section),
      _createElementVNode("section", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_ctx.question)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (_ctx.isLoading)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[2] || (_cache[2] = [
                      _createElementVNode("div", { class: "line" }, null, -1),
                      _createElementVNode("div", { class: "subline inc" }, null, -1),
                      _createElementVNode("div", { class: "subline dec" }, null, -1)
                    ])))
                  : _createCommentVNode("", true),
                (_ctx.isLoading)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("h2", null, _toDisplayString(_ctx.currentPickerTitle), 1),
                  _createElementVNode("button", {
                    class: "next_btn",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClickNext && _ctx.onClickNext(...args)))
                  }, _cache[3] || (_cache[3] = [
                    _createTextVNode(" Next "),
                    _createElementVNode("img", {
                      src: _imports_0,
                      alt: ""
                    }, null, -1)
                  ])),
                  _createElementVNode("button", {
                    class: "filter_close",
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClose && _ctx.onClose(...args)))
                  }, _cache[4] || (_cache[4] = [
                    _createElementVNode("img", {
                      src: _imports_1,
                      alt: ""
                    }, null, -1)
                  ]))
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("h3", null, [
                    _cache[5] || (_cache[5] = _createTextVNode(" Topic: ")),
                    _createElementVNode("span", null, [
                      _createVNode(_component_router_link, {
                        target: "_blank",
                        to: {
                    name: 'Topic',
                    params: {
                      slug: _ctx.question.topics?.slug,
                      tab: _ctx.TopicPageTab.QUESTION,
                    },
                  }
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.question.topics?.title), 1)
                        ]),
                        _: 1
                      }, 8, ["to"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.question.title), 1),
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(_component_answer_section, {
                      answers: _ctx.question.answers ?? [],
                      questionId: _ctx.question.id,
                      answersCount: _ctx.question.answer_count ?? 0,
                      answerValidate: _ctx.question.answer_validate,
                      onOnShowAuthModal: _ctx.toggleAuthModal
                    }, null, 8, ["answers", "questionId", "answersCount", "answerValidate", "onOnShowAuthModal"]),
                    _createVNode(_component_resource_section, {
                      resources: _ctx.question.resources ?? [],
                      questionId: _ctx.question.id,
                      resourcesCount: _ctx.question.resource_count ?? 0,
                      resource_validate: _ctx.question.resource_validate,
                      onOnShowAuthModal: _ctx.toggleAuthModal
                    }, null, 8, ["resources", "questionId", "resourcesCount", "resource_validate", "onOnShowAuthModal"])
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createVNode(_component_auth_modal, {
      title: "Login or Create to unlock this functionality",
      show: _ctx.isShowAuthModal,
      onOnClose: _ctx.toggleAuthModal
    }, null, 8, ["show", "onOnClose"]),
    _createVNode(_component_loader, { show: _ctx.isPageLoading }, null, 8, ["show"])
  ], 64))
}